import * as React from 'react';
import { Helmet } from 'react-helmet';

import fonts from 'helpers/seo/fonts';

const NotFoundPage: React.FC = () => {
  const url = typeof window !== 'undefined' ? window.document.referrer : null;

  return (
    <div className='success'>
      <Helmet htmlAttributes={{ lang: 'en' }} link={fonts} title='Success!' />

      <main className='content'>
        <h1 className='success-title'>Thank you!</h1>
        <p className='success-text'>Your form was successfully submitted.</p>
        {url ? (
          <a href={url}>
            <button type='submit' className='gradient-button prev-page-btn'>
              <span>Back to previous page</span>
            </button>
          </a>
        ) : null}
      </main>

      <footer className='footer'>
        <div className='powered-by'>Powered by</div>
        <div className='logo'>
          <span>endpoint</span>
          <div className='ellipse' />
          <span>space</span>
        </div>
      </footer>
    </div>
  );
};

export default NotFoundPage;
